import React from "react"
import style from "./form-wrapper.mod.scss"

const FormWrapper = ({ children, heading, text }) => {
  return (
    <div className={style.wrapper}>
      {heading && <h3 className={style.wrapper__heading}>{heading}</h3>}
      {text && <p className={style.wrapper__text}>{text}</p>}
      <div>{children}</div>
    </div>
  )
}

export default FormWrapper
