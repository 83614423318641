import React, { useState } from "react"
import { handleLogin, isLoggedIn } from "../../services/auth"
import { FormProvider, useForm } from "react-hook-form"
import Input from "../form/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Button from "../button/button"
import FormWrapper from "../form/form-wrapper"
import { toast } from "react-toastify"
import { Link } from "gatsby"

const schema = yup
  .object({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup.string().required("Password Name is required"),
  })
  .required()

const LoginForm = ({ handleLoggedIn }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")
  const [processing, setProcessing] = useState(false)

  const onSubmit = async (data) => {
    setProcessing(true)

    handleLogin(data).then((res) => {
      if (res.status) {
        handleLoggedIn()
      } else if (res.message) {
        toast.error(res.message)
        setProcessing(false)
      }
    })
  }

  return (
    <Container width={"full"} pt={0} pb={0}>
      {!!generalMsg && <p className="input--error">{generalMsg}</p>}
      <FormWrapper
        heading={"Login"}
        text={"Please Log In if you have an account already"}
      >
        <FormProvider {...methods}>
          <form
            className="form__login"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Input
              label={"Email"}
              name="email"
              {...register("email")}
              required
              placeholder={"Your Email"}
              errorMessage={errors.email?.message}
            />
            <Input
              label={"Password"}
              name="password"
              type="password"
              {...register("password")}
              required
              placeholder={"Your Password"}
              errorMessage={errors.password?.message}
            />
            <Container pt={1}>
              <Button primary disabled={processing} fullWidth type="submit">
                {processing ? "Logging in..." : "Log In"}
              </Button>
            </Container>
            <Container pt={1} textAlign="right">
              <Link to="/register">
                <small>Register</small>
              </Link>
            </Container>
            <Container pt={0} textAlign="right">
              <Link to="/forgot-password-request">
                <small>Forgot Password?</small>
              </Link>
            </Container>
          </form>
        </FormProvider>
      </FormWrapper>
    </Container>
  )
}

export default LoginForm
