import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Layout from "../components/global/layout"
import { AuthContext } from "../context/AuthContext"
import Container from "../components/container/container"
import SimpleIntro from "../components/global/simple-intro"
import LoginForm from "../components/login/login-form"
import { toast } from "react-toastify"

const Login = ({ component: Component, location }) => {
  const { contextIsUserLoggedIn, handleContextCheckLoggedIn, isGuestUser } =
    useContext(AuthContext)

  useEffect(() => {
    if (contextIsUserLoggedIn) {
      navigate(`/account`)
    }
  }, [contextIsUserLoggedIn])

  useEffect(() => {
    //check if location.state.generalMsg exists
    if (location.state && location.state.generalMsg) {
      toast.success(location.state.generalMsg)
    }
  }, [])

  const handleLoggedIn = () => {
    navigate(`/wine`)
  }

  return (
    <Layout whitePreFooter={true}>
      <Container gutters width={"xsmall"}>
        <SimpleIntro title={"Login"} />
        <LoginForm handleLoggedIn={handleLoggedIn} />
      </Container>
    </Layout>
  )
}

export default Login
